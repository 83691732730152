import Swiper from 'swiper';
import { Scrollbar, Autoplay, Pagination, Navigation, Manipulation } from 'swiper/modules';

// Add to window
window.Swiper = Swiper;
window.Swiper_Scrollbar = Scrollbar;
window.Swiper_Autoplay = Autoplay;
window.Swiper_Pagination = Pagination;
window.Swiper_Navigation = Navigation;
window.Swiper_Manipulation = Manipulation;
